import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';

export default class WorkWithUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      error: false,
      message: '',
    };
  }

  render() {
    const validationSchema = Yup.object().shape({
      yourName: Yup.string()
        .min(3, 'Must be at least ')
        .max('50', 'Too long to be a name!')
        .required('Must enter a name'),
      yourEmail: Yup.string()
        .email('Must be a valid email address')
        .max('50', 'Too long to be an email!')
        .required('Must enter an email address'),
      yourMobile: Yup.string()
        .min(7, 'Must be at least ')
        .max('15', 'Too long to be a number!')
        .required('Must enter a mobile'),
    });

    return (
      <Formik
        initialValues={{
          yourName: '',
          yourMobile: '',
          yourEmail: '',
          yourMessage: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (this.state.success === true) {
            // console.log("prevented");
            return;
          }
          setSubmitting(true);
          // console.log(values);
          // console.log("submitting");
          let bodyFormData = new FormData();
          bodyFormData.set('yourName', values.yourName);
          bodyFormData.set('yourMobile', values.yourMobile);
          bodyFormData.set('yourEmail', values.yourEmail);
          bodyFormData.set('yourMessage', values.yourMessage);

          Axios.post(
            `${process.env.WORDPRESS_FORMS}/wp-json/contact-form-7/v1/contact-forms/561/feedback`,
            bodyFormData
          )
            .then((res) => {
              this.setState({
                message: res.data.message,
              });
              if (res.data.status === 'mail_sent') {
                this.setState({
                  success: true,
                });
                resetForm();
              } else {
                this.setState({
                  error: true,
                });
              }
            })
            .catch((err) => {
              this.setState({
                success: false,
                error: true,
                message: 'Some error has occured, try again.',
              });
            });

          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className='contactus' onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}

            <input
              className={`form-control text ${
                touched.yourName && errors.yourName ? 'is-invalid' : null
              }`}
              type='text'
              name='yourName'
              id='name'
              placeholder='Name'
              onChange={handleChange}
              value={values.yourName}
              onBlur={handleBlur}
            />

            <input
              className={`form-control text ${
                touched.yourMobile && errors.yourMobile ? 'is-invalid' : null
              }`}
              type='text'
              name='yourMobile'
              id='mobile'
              placeholder='Mobile'
              onChange={handleChange}
              value={values.yourMobile}
              onBlur={handleBlur}
            />

            <input
              className={`form-control text ${
                touched.yourEmail && errors.yourEmail ? 'is-invalid' : null
              }`}
              type='email'
              name='yourEmail'
              id='email'
              placeholder='Email'
              onChange={handleChange}
              value={values.yourEmail}
              onBlur={handleBlur}
            />
            <textarea
              className={`message ${
                touched.yourMessage && errors.yourMessage ? 'is-invalid' : null
              }`}
              cols='30'
              rows='5'
              name='yourMessage'
              id='message'
              placeholder='Message'
              onChange={handleChange}
              value={values.yourMessage}
              onBlur={handleBlur}
            />
            <button
              type='submit'
              aria-label='Submit the form'
              className='btn btn-block btn-primary'
              disabled={isSubmitting}
            >
              Submit
            </button>

            {this.state.message.length > 0 && (
              <div className='text-center'>
                <span
                  className={`alert d-block mt-3 alert-${
                    this.state.success ? 'success' : 'danger'
                  }`}
                >
                  {this.state.message}
                </span>
              </div>
            )}
          </form>
        )}
      </Formik>
    );
  }
}
